import { datadogRum } from "@datadog/browser-rum";

export const initDatadog = () => {
  if (
    !process.env.REACT_APP_ALLOW_SESSION_MONITORING ||
    !process.env.REACT_APP_DATADOG_APP_ID ||
    !process.env.REACT_APP_DATADOG_CLIENT_TOKEN ||
    !process.env.REACT_APP_DATADOG_SITE ||
    !process.env.REACT_APP_DATADOG_SERVICE ||
    !process.env.REACT_APP_DATADOG_ENV
  ) {
    return;
  }

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DATADOG_ENV,
    sessionSampleRate: 80,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input"
  });
};
